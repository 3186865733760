import { FC } from "react";
import SlotCard from "../../components/template/card";
import AcademyDetailForm from "../../components/adacemy/detailsForm";


const NewAcademy = () => {

    return (
        <AcademyDetailForm />
    )
}

export default NewAcademy